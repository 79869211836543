<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      ©
      <b-link class="ml-25" href="https://hps.net.br" target="_blank"
        >ACADEMIA WORLDFITNESS.</b-link
      >
      <span class="d-none d-sm-inline-block"
        >, 2019-{{ new Date().getFullYear() }} Todos os direitos reservados.
      </span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <span>Com</span>
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
      <span> por </span>
      <b-link href="https://hps.net.br" target="_blank">HPSWeb</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
